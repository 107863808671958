* {
    transition: all 0.04s ease-in-out;
}

.App-content {
  margin: 0 5%; /* Default margin for small screens */
}

@media (min-width: 768px) {
  .App-content {
    margin: 0 5%; /* Larger margin for medium screens and up */
  }
}