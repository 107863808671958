.navbar {
  margin-bottom: 5%;
}

.navbar-brand {
  font-size: 25px; /* Adjust size as needed */
  margin-right: auto !important;
}

/*.logout {*/
/*  color: #dc3545;*/
/*}*/
